.topbar {
    background-color: #4267B2; /* Facebook blue color */
  }
  
  .logo-link {
    text-decoration: none;
  }
  
  .logo {
    width: 40px;
    height: 40px;
  }
  