.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  height: 100vh;
  background-image: url('../../assets/dashBackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.reset-password-content {
  display: flex;
  align-items: center;
}

.reset-password-box {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
}

.reset-password-title {
  margin-bottom: 20px;
}

.reset-password-form .reset-password-input {
  margin-bottom: 20px;
}

.reset-password-button {
  margin-top: 10px;
}