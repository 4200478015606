/* Diagnosis.css */
.diagnosis-container {
  padding: 20px;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #f5f5f5;
  overflow: auto;
}

.diagnosis-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.diagnosis-textarea-wrapper {
  position: relative;
  margin-top: 10px;
}

.ai-diagnosis-textfield {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  min-height: 70vh;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.ai-diagnosis-textfield::-webkit-scrollbar {
  width: 8px;
}

.ai-diagnosis-textfield::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.copy-button {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .ai-diagnosis-textfield {
    max-height: 300px;
  }
}