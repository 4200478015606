.context-document-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  overflow: auto;
  background-color: rgb(255, 254, 253);
}

.context-document-title {
  font-weight: bold;
}

.context-document-actions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.context-document-textarea {
  flex-grow: 1;
  width: 50%;
  height: calc(100vh - 140px);
  resize: vertical;
  background: rgb(255, 255, 255);
  padding: 10px;
  border: 1px solid #ccc;
}

.clear-button {
  margin-top: 20px;
}

.qr-code-button {
  font-size: 18px;
  padding: 10px 20px;
}

.qr-code-container {
  margin: 20px 0;
}

.upload-button {
  font-size: 16px;
  margin-top: 20px;
}

.uploaded-image {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
}
