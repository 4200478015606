/* Main container styling */
.history-container {
  padding: 20px;
  margin: auto;
  max-width: 95%; /* Adjust as needed */
  background-color: #f5f5f5;
  border-radius: 8px;
}

/* Title Styling */
.history-title {
  font-weight: bold;
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

/* Copied Message */
.copied-message {
  text-align: center;
  background-color: #4caf50;
  color: white;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: none;
  animation: fadeIn 0.5s ease;
}

.copied-message.show {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* History List */
.history-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

/* History Item */
.history-item {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Timestamp */
.timestamp {
  font-size: 0.85rem;
  color: #888;
  text-align: right;
  margin-bottom: 10px;
}

/* Section Header */
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.section-title {
  font-weight: 600;
  font-size: 1rem;
  color: #333;
}

/* Copy Button */
.copy-button {
  color: #555;
  transition: color 0.2s ease;
}

.copy-button:hover {
  color: #000;
}

/* Response and Transcription Boxes */
.response-box {
  background-color: #e0f7fa; /* Light cyan for response */
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}

.transcription-box {
  background-color: #fff3e0; /* Light orange for query */
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
}

/* Text Content */
.response-text,
.transcription-text {
  font-size: 0.95rem;
  color: #444;
}

/* Expand Actions */
.expand-actions {
  padding: 0;
  margin: 0;
}

.expand-actions .MuiButton-root {
  text-transform: none;
  font-size: 0.85rem;
  color: #007bff;
}

.expand-icon {
  transition: transform 0.3s ease;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}

/* Responsive Design */
@media (max-width: 600px) {
  .history-container {
    padding: 15px;
  }

  .history-title {
    font-size: 1.5rem;
  }

  .section-title {
    font-size: 0.95rem;
  }

  .response-text,
  .transcription-text {
    font-size: 0.9rem;
  }
}