/* Transcribe.css */

/* Global styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
}

/* Remove default input styles for better consistency */
input,
textarea {
  outline: none;
  border: none;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .MuiTypography-h4 {
    font-size: 1.5rem;
  }

  .MuiButton-root {
    width: 100%;
  }
}