/* Login.css */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: url('../../assets/dashBackground.png');
  background-color: #555;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.login-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.login-content {
  padding-top: 100px;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.login-box {
  padding: 40px 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  text-align: center;
}

.login-title {
  margin-bottom: 10px;
  font-weight: bold;
  color: #1470cc; /* Primary color */
}

.login-subtitle {
  margin-bottom: 30px;
  color: #555;
}

.login-form .login-input {
  margin-bottom: 20px;
}

.login-input .MuiInputBase-root {
  background-color: #fff;
}

.login-button {
  margin-top: 10px;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  text-transform: none;
}

.login-button:hover {
  background-color: #1e0080;
}

.forgot-password-link {
  display: block;
  margin-top: 15px;
  color: #b3001b;
  text-decoration: none;
}

.forgot-password-link:hover {
  color: #1e0080;
}

.signup-text {
  margin-top: 20px;
}

.signup-link {
  color: #2a00b3;
  text-decoration: none;
  font-weight: bold;
}

.signup-link:hover {
  color: #9aba31;
}